$modal-mobile-gap: 16px;
$modal-tablet-gap: 64px;

.modal {
    @include apply-screen-size(desktop-sm) {
        .show-cookie-notification &::after {
            min-height: 100px;
        }
    }

    position: fixed;
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    display: none;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    width: 100%;

    &.is-active {
        display: flex;
    }
}

.modal__inner {
    --modal-vertical-gap: 16px;

    position: relative;
    overflow: hidden;
    max-width: 818px;
    width: 100%;
    height: calc(100% - var(--modal-vertical-gap));
    margin-top: auto;
    padding: 60px 0 0;
    border-radius: 16px 16px 0 0;
    background: #fff;

    @media screen and (min-width: $media-desktop) {
        --modal-vertical-gap: 24px;

        padding: 70px 104px 0;
    }
    @media screen and (max-width: $media-desktop) and (orientation: landscape) {
        overflow-x: hidden;
        overflow-y: scroll;
    }
}

.modal__frame {
    position: relative;
    flex: 0 0 auto;
    max-width: 100%;
    height: 100%;
    background: #fff;
    transform: translateZ(0);
}

.modal__fade {
    position: fixed;
    top: -100px;
    right: 0;
    bottom: -100px;
    left: 0;
    display: block;
    background: rgba(0, 0, 0, 0.8);
}

.modal__control {
    position: absolute;
    top: 12px;
    right: 12px;
    height: auto;
}

.modal__close {
    color: #767676;
    cursor: pointer;

    @media screen and (min-width: $media-desktop) {
        padding: $modal-mobile-gap;
    }
}

.modal__content {
    width: 100%;
    height: 100%;
    margin: auto;
    padding-bottom: 24px;
    border: none;
    opacity: 1;
    visibility: visible;
    transition: 300ms opacity ease-in-out;
    -webkit-overflow-scrolling: touch;

    &.is-hidden {
        opacity: 0;
        visibility: hidden;
    }

    @media screen and (min-width: $media-desktop) {
        max-height: none;
    }
}

.modal__content-inner--center {
    text-align: center;
}

.modal__title {
    font-size: 24px;
    line-height: 1.166;
}

.modal__title + .modal__text {
    margin-top: 32px;
}

.modal--primary {
    .modal__fade {
        background-color: var(--color-primary);
    }

    .modal__inner {
        height: auto;
        margin: auto;
        padding: 0;
        background-color: transparent;
    }

    // .modal__control {
    //     display: none;
    // }
    .modal__control {
        position: fixed;
        display: block;

        // temporary
        .icon {
            color: var(--color-base);
            transform: scale(1.5);
        }
    }

    .modal__frame {
        height: auto;
        background-color: transparent;
    }

    .modal__content {
        pointer-events: auto;
    }
}

.modal--secondary {
    .modal__fade {
        background-color: var(--color-base);
    }

    .modal__inner {
        height: auto;
        margin: auto;
        padding: 0;
        background-color: transparent;
    }

    .modal__control {
        position: fixed;
        display: block;

        // temporary
        .icon {
            transform: scale(1.5);
        }
    }

    .modal__frame {
        height: auto;
        background-color: transparent;
    }

    .modal__content {
        pointer-events: auto;
    }
}
