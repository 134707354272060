$dropdown-list-top-gap: 12px;

.dropdown-list {
    position: absolute;
    z-index: 90;
    top: 100%;
    left: 0;
    margin-top: $dropdown-list-top-gap;
    color: var(--color-base);
    opacity: 0;
    visibility: hidden;
    transition: .2s opacity ease;

    &::before {
        display: block;
        width: 100%;
        height: $dropdown-list-top-gap;
        margin-top: -$dropdown-list-top-gap;
        content: '';
    }

    .trigger-dropdown-list.is-active & {
        opacity: 1;
        visibility: visible;
    }

    .language-selector__inner:hover & {
        opacity: 1;
        visibility: visible;
    }

    .language-selector__trigger:checked + .language-selector__dropdown & {
        opacity: 1;
        visibility: visible;
    }
}

.dropdown-list--top {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: $dropdown-list-top-gap;

    &::before {
        display: none;
    }

    &::after {
        display: block;
        width: 100%;
        height: $dropdown-list-top-gap;
        margin-bottom: -$dropdown-list-top-gap;
        content: '';
    }
}

.dropdown-list--center {
    right: auto;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
}

.dropdown-list--fullwidth {
    min-width: 100%;
}

.dropdown-list__inner {
    overflow-y: scroll;
    max-height: 280px;
    padding-top: 8px;
    padding-bottom: 8px;
    border: 2px solid var(--color-grey-light);
    background: #fff;
}

.dropdown-list__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    color: currentColor;
    text-decoration: none;
    transition: .2s color ease;
    cursor: pointer;
}

.dropdown-list__item-title {
    white-space: nowrap;
    font-size: 14px;
    line-height: 1.2;
    pointer-events: none;
}

.dropdown-list__item-label {
    font-size: 14px;
    line-height: 1.2;
    pointer-events: none;
}

.trigger-dropdown-list {
    position: relative;
    cursor: pointer;
}

.dropdown-list__item {
    &:hover,
    &.is-active {
        color: var(--color-primary);
    }

    &.is-active {
        cursor: default;
    }
}

.dropdown-list__item-title + .dropdown-list__item-label {
    margin-left: 16px;
}
