@use "sass:math";

@import '../layout/layout.reference.scss';

$font-family-custom-book: var(--font-family-book, 'Circular20WebCyr-Book');
$font-family-custom-bold: var(--font-family-bold, 'Circular20WebCyr-Bold');
$font-family-custom-black: var(--font-family-black, 'Circular20WebCyr-Black');
$font-family-custom-extra-black: var(--font-family-extra-black, 'CircularXXWeb-ExtraBlack');

$typography: (
    'header-1': (
        'font-family': $font-family-custom-extra-black,
        'font-weight': var(--font-weight-black, 900),
        'font-size-mobile-min': 34px,
        'font-size-mobile-max': 40px,
        'line-height-mobile': 1.15,
        'font-size-desktop-min': 40px,
        'font-size-desktop-max': 60px,
        'line-height-desktop': 1.08,
        'breakpoint-desktop-min': 640px,
        'breakpoint-desktop-max': 1200px
    ),
    'header-2': (
        'font-family': $font-family-custom-bold,
        'font-weight': var(--font-weight-bold, 700),
        'font-size-mobile-min': 20px,
        'font-size-mobile-max': 30px,
        'line-height-mobile': 1.09,
        'font-size-desktop-min': 30px,
        'font-size-desktop-max': 50px,
        'line-height-desktop': 1.1,
        'breakpoint-desktop-min': 640px,
        'breakpoint-desktop-max': 1200px
    ),
    // this one isn't by design styleguide + used only in one place => to be reviewed
    'header-3': (
        'font-family': $font-family-custom-bold,
        'font-weight': var(--font-weight-bold, 700),
        'font-size-mobile-min': 20px,
        'font-size-mobile-max': 30px,
        'line-height-mobile': 1.09,
        'font-size-desktop-min': 25px,
        'font-size-desktop-max': 40px,
        'line-height-desktop': 1.09,
        'breakpoint-desktop-min': 640px,
        'breakpoint-desktop-max': 1200px
    ),
    'header-4': (
        'font-family': $font-family-custom-book,
        'font-weight': var(--font-weight-book, 400),
        'font-size-mobile-min': 14px,
        'font-size-mobile-max': 25px,
        'font-size-desktop-min': 25px,
        'font-size-desktop-max': 40px,
        'line-height-mobile': 1.28,
        'line-height-desktop': 1.15,
        'breakpoint-desktop-min': 640px,
        'breakpoint-desktop-max': 1200px
    ),
    // this one is actual header-3 by designs
    'caption': (
        'font-family': $font-family-custom-black,
        'font-weight': var(--font-weight-black, 900),
        'font-size-mobile-min': 12px,
        'font-size-mobile-max': 16px,
        'line-height-mobile': 1.1,
        'font-size-desktop-min': 16px,
        'font-size-desktop-max': 20px,
        'line-height-desktop': 1.1,
        'breakpoint-desktop-min': 640px,
        'breakpoint-desktop-max': 1200px
    ),
);

$p1-font-family: $font-family-custom-book;
$p1-font-weight: var(--font-weight-book, normal);
$p1-font-size: var(--p1-font-size, 14px);
$p1-line-height: var(--p1-line-height, 18px);

$button-font-family: $font-family-custom-book;
$button-font-weight: var(--font-weight-medium, 500);
$button-font-size: var(--button-font-size, 14px);
$button-line-height: var(--button-line-height, 18px);

$mobileBottomBreakpoint: 320px;

// Implementation for responsive typography is taken from
// https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/#for-those-who-dont-mind-that-edge-case
@function responsive-typo ($breakpointMin, $breakpointMax, $fontSizeMin, $fontSizeMax) {
    $slope: math.div($fontSizeMax - $fontSizeMin, $breakpointMax - $breakpointMin);
    $yAxisIntersection: -1 * $breakpointMin * $slope + $fontSizeMin;

    // --resize-speed allows to increase speed of recize in case when we need the text to be resized more quick
    // i.e. in case of multiple cards
    @return clamp(#{$fontSizeMin}, calc((#{$yAxisIntersection} + #{$slope * 100}vw) * var(--resize-speed, 1)), #{$fontSizeMax});
}

// this mixin isn't used anywhere but can be an idea for further typography improvements
// it would be nice to have responsive typography with possibility to pass exact sizes in rare cases
// boolean native-variable --is-responsive can be used for toggling responsiveness / static behaviour
//@mixin responsive-typo-native($breakpointMin, $desktopTopBreakpoint) {
//    --slope: calc((var(--font-size-max) - var(--font-size-min)) / (var(--breakpoint-max) - var(--breakpoint-min)));
//    --axis-intersection: calc(-1px * var(--breakpoint-max) * var(--slope));
//
//    font-size: clamp(
//        calc(var(--font-size-min, var(--font-size)) * 1px),
//        var(--axis-intersection) + calc(var(--slope) * 100vw),
//        calc(var(--font-size-max, var(--font-size)) * 1px)
//    );
//}

@mixin apply-responsive-font-size($key, $isResponsive: false) {
    $props: map-get($typography, $key);
    $desktopBottomBreakpoint: map-get($props, breakpoint-desktop-min);
    $desktopTopBreakpoint: map-get($props, breakpoint-desktop-max);
    $fontSizeMobileMin: map-get($props, font-size-mobile-min);
    $fontSizeMobileMax: map-get($props, font-size-mobile-max);
    $fontSizeDesktopMin: map-get($props, font-size-desktop-min);
    $fontSizeDesktopMax: map-get($props, font-size-desktop-max);

    font-weight: map-get($props, font-weight);
    font-size: var(--font-size, #{map-get($props, font-size-mobile-min)});
    line-height: var(--line-height, #{map-get($props, line-height-mobile)});
    font-family: map-get($props, font-family);

    @media (min-width: $desktopBottomBreakpoint + 1px) {
        --line-height: #{map-get($props, line-height-desktop)};
    }

    @if $isResponsive {
        --font-size: #{responsive-typo($mobileBottomBreakpoint, $desktopBottomBreakpoint, $fontSizeMobileMin, $fontSizeMobileMax)};

        @media (min-width: $desktopBottomBreakpoint + 1px) {
            --font-size: #{responsive-typo($desktopBottomBreakpoint, $desktopTopBreakpoint, $fontSizeDesktopMin, $fontSizeDesktopMax)};
        }
    }

    @else {
        @media (min-width: $desktopBottomBreakpoint + 1px) {
            --font-size: #{$fontSizeDesktopMax};
        }
    }
}
@mixin apply-typography-header1($isResponsive: false) {
    @include apply-responsive-font-size('header-1', $isResponsive);
}
@mixin apply-typography-header2($isResponsive: false) {
    @include apply-responsive-font-size('header-2', $isResponsive);
}
// this one isn't by design styleguide + used only in one place with inherited behaviour => to be reviewed
@mixin apply-typography-header3($isResponsive: false) {
    @include apply-responsive-font-size('header-3', $isResponsive);
}
@mixin apply-typography-header4($isResponsive: false) {
    @include apply-responsive-font-size('header-4', $isResponsive);
}
@mixin apply-typography-caption($isResponsive: false) {
    @include apply-responsive-font-size('caption', $isResponsive);

    text-transform: uppercase;
    letter-spacing: 0.1em;
}
@mixin apply-typography-p1() {
    @include apply-screen-size(desktop-xlg) {
        --p1-font-size: 22px;
        --p1-line-height: 26px;
    }

    font-weight: $p1-font-weight;
    font-size: $p1-font-size;
    line-height: $p1-line-height;
    font-family: $p1-font-family;
}
@mixin apply-typography-button-normal() {
    font-weight: $button-font-weight;
    font-size: $button-font-size;
    line-height: $button-line-height;
    font-family: $button-font-family;
}
@mixin apply-typography-button-large() {
    @include apply-screen-size(desktop-xlg) {
        font-size: $desktop-p1-font-size;
        line-height: $desktop-p1-line-height;
    }

    font-size: $mobile-p1-font-size;
    line-height: $mobile-p1-line-height;
}
@mixin apply-text-pallete() {
    color: var(--text-color, var(--color-base));
}
@mixin apply-text-inherited() {
    &.is-inherited {
        font-size: inherit;
    }

    p + p {
        margin-top: 1em;
    }
}
