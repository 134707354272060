.icon {
    position: relative;
    display: block;
    width: 24px;
    height: 24px;
    fill: currentColor;
    -webkit-font-smoothing: antialiased;
}

.icon__content {
    width: inherit;
    height: inherit;
    fill: inherit;
    pointer-events: none;
}

.icon--sm {
    width: 16px;
    height: 16px;
}

.icon--xsm {
    width: 20px;
    height: 20px;
}

.icon--stretch {
    width: inherit;
    height: inherit;
}

.icon--inline {
    display: inline;
}

.icon--primary {
    color: var(--color-primary);

    .badoo & {
        --color-primary: #783cfa;
    }
}
