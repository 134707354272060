// @todo: later cleanup and cover with media-queries tests
.swiper-container {
    overflow: visible;
}

.swiper__arrow {
    --arrow-width: 50px;

    @include apply-screen-size(desktop-sm) {
        --arrow-width: 64px;
    }

    position: absolute;
    z-index: 1;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--arrow-width);
    height: 100%;
    cursor: pointer;
}

.swiper__arrow--left {
    left: 0;
}

.swiper__arrow--right {
    right: 0;
}

.swiper__arrow-icon {
    --arrow--icon-size: 20px;
    --arrow-bg-color: #fff;
    --arrow-fr-color: var(--color-primary);

    @include apply-screen-size(desktop-sm) {
        --arrow--icon-size: 25px;
    }

    position: relative;
    display: block;
    width: var(--arrow--icon-size);
    height: var(--arrow--icon-size);
    color: var(--arrow-fr-color);

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        border-radius: 100%;
        background-color: var(--arrow-bg-color);
        transform-origin: 50%;
    }

    .swiper__arrow:active & {
        --arrow-bg-color: var(--color-primary);
        --arrow-fr-color: #fff;

        transition: color 200ms ease-in-out;

        &::before {
            transform: scale(1.1);
            transition: background-color 200ms ease-in-out;
        }
    }

    .swiper__arrow:hover.is-hoverable & {
        --arrow-bg-color: var(--color-primary);
        --arrow-fr-color: #fff;

        transition: color 200ms ease-in-out;

        &::before {
            transform: scale(1.1);
            transition: background-color 200ms ease-in-out;
        }
    }

    .badoo & {
        --color-primary: #783cfa;
    }
}

.swiper--arrows-outside-initialized {
    .swiper__arrow--left {
        left: calc(-1 * var(--content-section-padding-horizontal));
    }

    .swiper__arrow--right {
        right: calc(-1 * var(--content-section-padding-horizontal));
    }
}

.swiper--arrows-inside {
    position: relative;
    overflow: hidden;
    padding: 0 var(--content-section-padding-horizontal);
}

.swiper__arrow--secondary {
    .swiper__arrow-icon {
        --arrow-bg-color: transparent;
        --arrow-fr-color: #fff;

        .swiper__arrow:hover & {
            --arrow-bg-color: #fff;
            --arrow-fr-color: transparent;
        }
    }
}

.swiper-container--arrows-within {
    display: flex;
    width: 100%;

    .swiper-container {
        overflow: hidden;
    }

    .swiper__arrow {
        @include apply-screen-size(desktop-sm) {
            width: 90px;
        }

        position: relative;
        right: 0;
        left: 0;
        flex: 1 0 auto;
        width: 50px;
        height: auto;
    }

    .swiper__arrow-icon {
        width: 24px;
        height: 24px;
    }
}

.swiper-container--arrows-for-video-carousel {
    --arrow-size: 50px;

    @include apply-screen-size(desktop-md) {
        --arrow-size: 80px;
    }

    display: flex;
    width: 100%;

    .swiper-container {
        overflow: hidden;
    }

    .swiper--arrows-inside {
        padding: 0;
    }

    .swiper__arrow {
        // position: relative;
        align-self: center;
        width: var(--arrow-size);
    }
}
