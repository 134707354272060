$footer-menu-accordeon-title-min-width: 45%;

.footer-menu-accordeon {
    display: block;
}

.footer-menu-accordeon__title {
    @include apply-screen-size(desktop-lg) {
        line-height: 24px;
    }

    display: flex;
    justify-content: space-between;
    width: auto;
    color: var(--color-base-deep);
    white-space: nowrap;
    font-weight: var(--font-weight-bold);
    font-size: 15px;
    line-height: 18px;
    font-family: var(--font-family-bold);
    cursor: pointer;

    .icon {
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        margin-right: auto;
        margin-left: 20px;
    }
}

.footer-menu-accordeon__title-text {
    min-width: $footer-menu-accordeon-title-min-width;
}

.footer-menu-accordeon__title-link {
    display: flex;
    justify-content: space-between;
    min-width: $footer-menu-accordeon-title-min-width;
    width: auto;
    color: inherit;
    text-decoration: none;

    @media screen and (min-width: $media-desktop) {
        transition: 0.2s ease opacity;

        &:hover {
            opacity: 0.8;
        }
    }
}

.footer-menu-accordeon__list {
    display: block;
    padding: 0;
}

.footer-menu-accordeon__item {
    width: 100%;
}

.footer__inner-item--column {
    @include apply-screen-size(desktop-lg) {
        padding-bottom: 0;
    }

    padding-bottom: 24px;
}

.footer-menu-accordeon__link {
    color: var(--color-base);
    text-decoration: none;
    font-weight: var(--font-weight-book);
    font-size: 15px;
    line-height: 26px;
    font-family: var(--font-family-book);

    .footer-menu-accordeon__title & {
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
        font-family: inherit;
    }
}

.footer-menu-accordeon__content {
    color: var(--color-base);
}

.footer-menu-accordeon__content + .footer-menu-accordeon__social-links {
    margin-top: 24px;
}

.footer-menu-accordeon__title + .footer-menu-accordeon__content,
.footer-menu-accordeon__title + .footer-menu-accordeon__list {
    margin-top: 8px;
}

.footer-menu-accordeon__item + .footer-menu-accordeon__item {
    .footer-menu-accordeon__list--titles & {
        @include apply-screen-size(desktop-sm) {
            margin-top: 4px;
        }

        margin-top: 8px;
    }
}
