.footer-menu {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
}

.footer-menu__title {
    @include apply-screen-size(desktop-lg) {
        line-height: 24px;
    }

    color: var(--color-base-deep);
    white-space: nowrap;
    letter-spacing: -0.3px;
    font-weight: var(--font-weight-bold);
    font-size: 15px;
    line-height: 18px;
    font-family: var(--font-family-bold);
}

.footer-menu__title-link {
    color: inherit;
    text-decoration: none;

    @media screen and (min-width: $media-desktop) {
        transition: 0.2s ease opacity;

        &:hover {
            opacity: 0.8;
        }
    }
}

.footer-menu__list {
    display: block;
    padding: 0;
}

.footer-menu__item {
    display: inline-block;
    width: 100%;
}

.footer__inner-item--column {
    @include apply-screen-size(desktop-lg) {
        padding-bottom: 0;
    }

    padding-bottom: 24px;
}

.footer-menu__link {
    color: var(--color-base);
    text-decoration: none;
    font-weight: var(--font-weight-book);
    font-size: 15px;
    line-height: 26px;
    font-family: var(--font-family-book);

    .footer-menu__title & {
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
        font-family: inherit;
    }
}

.footer-menu__content {
    color: var(--color-base);
}

.footer-menu__content + .footer-menu__social-links {
    margin-top: 24px;
}

.footer-menu__title + .footer-menu__content,
.footer-menu__title + .footer-menu__list {
    margin-top: 20px;
}

.footer-menu__item + .footer-menu__item {
    .footer-menu__list--titles & {
        @include apply-screen-size(desktop-sm) {
            margin-top: 4px;
        }

        margin-top: 8px;
    }
}

.footer-menu__apps-links {
    @include apply-screen-size(desktop-sm) {
        padding-top: 24px;
    }

    margin-top: auto;
    padding-top: 8px;
}
