@import 'layout.reference';

//
// Layout composition
//

.layout {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow: hidden;
    max-width: 100vw;
    min-height: 100%;
    background: #fff; // @see UIKit

    @media print {
        display: block;
    }
}

.layout--background-inherited {
    background: inherit;
}

.layout__header {
    flex: 0 0 auto;

    @media print {
        display: none;
    }
}

.layout__content {
    position: relative;
    flex: 0 0 auto;
    max-width: $layout-max-width;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.layout__content--wide {
    max-width: none;
}

.layout__content--hpadded {
    @include apply-screen-size(desktop-sm) {
        padding-right: 47px;
        padding-left: 47px;
    }
    @include apply-screen-size(desktop-md) {
        padding-right: $layout-dw-gap;
        padding-left: $layout-dw-gap;
    }

    padding-right: $layout-mw-gap;
    padding-left: $layout-mw-gap;
}

.layout__content--vpadded {
    //& + & {
    //    padding-top: 0;
    //}

    @include apply-screen-size(desktop-sm) {
        padding-top: $layout-dw-vertical-gap;
        padding-bottom: $layout-dw-vertical-gap;
    }

    padding-top: $layout-mw-vertical-gap;
    padding-bottom: $layout-mw-vertical-gap;
}

@each $size, $value in map-get($layout-vertical-gap-map, 'mw') {
    .layout__content--top-padded-#{$size} {
        padding-top: $value;
    }
    .layout__content--bottom-padded-#{$size} {
        padding-bottom: $value;
    }
}
@each $size, $value in map-get($layout-vertical-gap-map, 'dw') {
    @include apply-screen-size(desktop-sm) {
        .layout__content--top-padded-#{$size} {
            padding-top: $value;
        }
        .layout__content--bottom-padded-#{$size} {
            padding-bottom: $value;
        }
    }
}

.layout > .layout__content:last-of-type {
    @include apply-screen-size(desktop-sm) {
        padding-bottom: $layout-dw-vertical-gap;
    }

    padding-bottom: $layout-mw-vertical-gap;
}

.layout__content--centered {
    text-align: center;
}

.layout__content--promo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;
}

.layout__footer {
    flex: 0 0 auto;
    margin-top: auto;

    & + & {
        margin-top: 0;
    }

    @media print {
        display: none;
    }
}

.layout__footer--hpadded {
    padding-right: $layout-dw-gap;
    padding-left: $layout-dw-gap;
}

.layout__footer--vpadded {
    @include apply-screen-size(desktop-sm) {
        padding-top: $layout-dw-vertical-gap;
        padding-bottom: $layout-dw-vertical-gap;
    }

    padding-top: $layout-mw-vertical-gap;
    padding-bottom: $layout-mw-vertical-gap;
}

.layout__footer--extra-margin {
    margin-top: 100px;
}

// Layout variations
//

.layout--centered-content > .layout__content {
    flex: 0 0 auto;
    margin-top: auto;
}

.layout--centered-content > .layout__content:last-of-type {
    margin-bottom: auto;
}

.layout--stretched-content > .layout__content {
    flex: 1 1 auto;
}

// Debug
@mixin apply-layout-debugger($color_odd, $color_even, $name) {
    position: relative;

    &:nth-child(odd) {
        background-color: rgba($color_odd, 0.1);
    }

    &:nth-child(even) {
        background-color: rgba($color_even, 0.1);
    }

    &::after {
        position: absolute;
        top: -10px;
        left: 0;
        content: '#{$name}';
        color: #999;
        font-size: 10px;
        line-height: 1;
        font-family:
            -apple-system,
            system-ui,
            BlinkMacSystemFont,
            Segoe UI,
            Roboto,
            Helvetica Neue,
            Arial,
            sans-serif;
    }
}

//.layout__content { @include apply-layout-debugger(#880e4f, #1a237e, 'layout__content'); }
