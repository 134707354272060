// TODO: ways to improve
// 1) there should be one custom font named 'Circular', boldness should be changed by font-weight, not font-family
// 2) I'm not sure how standard the typography is, but ideally if typography is changed between desktop and mobile,
// we should either rerender whole component (use P2 instead of P3 for example), or use custom typography
// and change font-size (maybe line-height too) via css variables
// 3) box-shadow around the photo - to mixins, it's reused widely
// 4) proper gaps for desktop and mobile versions. Better use native variables (i.e. --page-gap-vertical), that are redefined for desktop devices
// 5) we should stop mixing JS media queries and css - only one approach should be used widely

.quotes-carousel {
    position: relative;
    overflow-x: hidden;

    .android &,
    .ios & {
        pointer-events: none;

        .swiper__arrow {
            pointer-events: all;
        }
    }

    .swiper-slide {
        align-self: flex-end;
    }
}

.quotes-carousel__list {
    display: flex;
    align-items: center;
    width: 100%;
}

.quotes-carousel__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    min-height: inherit;
    padding: var(--content-section-padding-vertical) var(--content-section-padding-horizontal);
}

.quotes-carousel__card--spacious {
    flex-direction: row;
    align-items: center;

    .quotes-carousel__text {
        margin-top: 0;
        margin-right: 32px;
        margin-bottom: 0;
        font-size: 35px;
    }

    .quotes-carousel__decoration {
        top: -80px;
        width: 76px;
        height: 28px;
    }

    .quotes-carousel__profile {
        display: block;
        flex: 0 0 auto;
        width: 30%;
        text-align: center;
    }

    .quotes-carousel__photo {
        @include apply-screen-size(desktop-md) {
            width: 204px;
            height: 412px;
        }

        width: 102px;
        height: 206px;
        margin: 0 auto 32px;
    }

    .quotes-carousel__name {
        font-size: 35px;
        line-height: 30px;
    }

    .quotes-carousel__position {
        font-size: 22px;
        line-height: 30px;
    }
}

// TODO: typography component isn't stable enough to be properly used. Here we should use proper components
.quotes-carousel__text {
    position: relative;
    flex: 1 1 auto;
    margin-top: 40px; // compensation for quote symbol
    margin-bottom: 60px;
    font-weight: 400; // Book
    font-size: 22px;
    line-height: 1.09;
    font-family: $font-family-custom-book;
}

.quotes-carousel__decoration {
    position: absolute;
    top: -38px;
    left: 0;
    width: 48px;
    height: 18px;

    > svg {
        width: 100%;
        height: 100%;
    }
}

.quotes-carousel__profile {
    display: flex;
    align-items: flex-end;
    flex: 1 1 auto;
}

.quotes-carousel__name {
    margin-bottom: 8px;
    font-weight: 900; // Black
    font-size: 25px;
    line-height: 20px;
    font-family: $font-family-custom-black;
}

.quotes-carousel__position {
    font-weight: 400; // Book
    font-size: 12px;
    line-height: 20px;
    font-family: $font-family-custom-book;
}

.quotes-carousel__photo {
    position: relative;
    overflow: hidden;
    flex: 0 0 auto;
    width: 80px;
    height: 161.56px;
    margin-right: 20px;
    border-radius: 10px;
    object-fit: cover;
    box-shadow:
        0 64.57px 54.37px rgba(0, 0, 0, 0.05),
        0 62px 62px rgba(0, 0, 0, 0.07),
        0 15px 17px rgba(0, 0, 0, 0.07),
        0 7px 14px rgba(0, 0, 0, 0.1),
        0 29px 38px rgba(0, 0, 0, 0.03),
        0 4px 12px rgba(0, 0, 0, 0.07),
        0 4px 4px rgba(0, 0, 0, 0.05),
        0 14px 11px rgba(0, 0, 0, 0.05); // TODO: probably should be moved to mixin
}
