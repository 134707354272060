.footer-grid-menu {
    @include apply-screen-size(desktop-sm) {
        display: flex;
    }

    flex: 1 0 auto;
    margin: 0;
    padding: 0;
}

.footer-grid-menu__item {
    display: flex;
    flex-direction: column;
}

.footer-grid-menu__item + .footer-grid-menu__item {
    @include apply-screen-size(desktop-sm) {
        margin-top: 0;
        margin-left: 40px;
    }

    margin-top: 8px;
}

.footer-grid-menu + .footer-grid-menu {
    @include apply-screen-size(desktop-sm) {
        margin-top: 24px;
    }

    margin-top: 8px;
}
