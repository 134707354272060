$media-mobile: 480px;

$media-tablet: 768px;
$media-tablet-max: $media-tablet - 1;

$media-desktop-sm: 900px;

$media-desktop: 1024px;
$media-desktop-max: $media-desktop - 1;

/*
 * add cosmos tokens
 */
$token-control-color: var(--color-primary);

// Loaders - from bumble
$token-color-gray-dark: #000;

$ui-loader-dot-size-m: 6px;
$ui-loader-gap-size-m: 6px;
$ui-loader-logo-height-m: 32px;

$ui-loader-dot-size-s: 4px;
$ui-loader-gap-size-s: 2px;
$ui-loader-logo-height-s: 24px;

$ui-loader-dot-size-l: 14px;
$ui-loader-gap-size-l: 8px;
$ui-loader-logo-height-l: 64px;
