.hero-block {
    position: relative;
    display: flex;
    justify-content: center;
    max-width: 100%;
    width: 100vw;
    padding-top: 32px;
    padding-bottom: 80px;

    .cta-box {
        padding-right: var(--content-section-padding-horizontal);
        padding-left: var(--content-section-padding-horizontal);
    }

    &.is-sticky {
        position: sticky;
        top: 75px;
        box-sizing: border-box;
        max-height: calc(100vh - 50px); // 50px is header-height from header, @todo: move from component to global
        padding: 0;

        .ios &,
        .android & {
            position: relative;
            top: auto;

            // complicated case, when we want to have sticky on mobile
            &.has-mobile-sticky {
                position: sticky;
                top: 50px; // 50px is header-height from header, @todo: move from component to global
                transition: transform 400ms;
            }
        }
    }
}

.hero-block__inner {
    display: flex;
    align-self: center;
}

.hero-block__title {
    @include apply-screen-size(desktop-sm) {
        padding-bottom: 44px;
    }

    padding-bottom: 32px;
    word-wrap: break-word;
}

.hero-block__front-content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.hero-block--fullscreen {
    height: 100vh;
}

.hero-block--fluid {
    height: auto;
}

.hero-block--vertical {
    flex-direction: column;
}

.hero-block--primary {
    --color-primary: var(--theme-color-dating);

    background-color: var(--color-primary);
}

.hero-block--extra-margin {
    @include apply-screen-size(tablet-md) {
        margin-bottom: 128px;
    }
}
