// TODO: it looks the font here should be responsive
// Check if title should have the same size both on mobile and desktop versions

.values-list {
    padding: var(--content-section-padding-vertical) var(--content-section-padding-horizontal);
    background: var(--color-primary);
    color: var(--color-base);

    // @todo add proper color definition
    .caption {
        color: var(--color-base);
    }

    @media (max-width: 460px) {
        padding-right: 10px;
        padding-left: 10px;
    }
}

.values-list__title {
    margin-bottom: 36px;
    text-align: center;
}

.values-list__items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(clamp(160px, 40%, 360px), 1fr));
    grid-gap: 10px 10px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;

    @media (max-width: 460px) {
        grid-template-columns: repeat(auto-fill, 1fr);
    }
}

.values-list__item {
    // TODO: add hover effect?
    list-style: none;
    cursor: pointer;
}

.values-list__item-symbol {
    position: relative;
    width: 100px;
    height: 100px;
    margin: 0 auto;
}

.values-list__item-title {
    --text-color: var(--color-base);

    margin-top: 16px;
    word-wrap: break-word;
    text-align: center;
}

.values-list--spacious {
    padding: 80px;

    .values-list__title {
        // TODO: increase size??
    }

    .values-list__item-symbol {
        width: 164px;
        height: 164px;
    }

    .values-list__items {
        grid-template-columns: repeat(auto-fill, minmax(clamp(300px, 30%, 360px), 1fr));
        grid-gap: 100px 20px;
    }

    .values-list__item-title {
        margin-top: 4px;
    }
}
