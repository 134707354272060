.footer {
    @include apply-screen-size(desktop-sm) {
        padding-right: var(--horizontal-gap-large);
        padding-left: var(--horizontal-gap-large);
    }

    padding-right: var(--horizontal-gap);
    padding-left: var(--horizontal-gap);
}

.footer__inner {
    @include apply-screen-size(desktop-md) {
        padding-top: 44px;
    }

    display: flex;
    flex-wrap: wrap;
    max-width: var(--page-max-width);
    margin: 0 auto;
    padding-top: 32px;
    padding-bottom: 24px;
    border-top: 2px solid var(--color-primary);
}

.footer__inner--columns {
    @include apply-screen-size(desktop-sm) {
        flex-direction: row;
    }

    flex-direction: column;
    justify-content: space-between;

    .footer__inner-item + .footer__inner-item {
        @include apply-screen-size(desktop-sm) {
            margin-top: 0;
        }

        margin-top: 8px;
        margin-left: 0;
    }
}

.footer__inner-item {
    @include apply-screen-size(desktop-sm) {
        width: calc(25% - calc(var(--horizontal-gap-large) * 3 / 4));

        .footer__inner--columns & {
            width: auto;
        }

        .footer-menu__item {
            display: block;
            width: auto;
        }
    }

    display: flex;
    flex-direction: column;
    width: 100%;
}

.footer__inner-item + .footer__inner-item {
    @include apply-screen-size(desktop-sm) {
        margin-top: 0;
        margin-left: var(--horizontal-gap-large);
    }

    margin-top: 8px;
}

.footer__caption {
    flex: 0 1 auto;
    margin-top: auto;
}

.footer__copyright {
    @include apply-screen-size(desktop-sm) {
        padding: 0;
        text-align: center;
    }

    flex: 0 0 auto;
    width: 100%;
    font-weight: var(--font-weight-book);
    font-size: 12px;
    line-height: 30px;
    font-family: var(--font-family-book);
}

.footer__inner + .footer__copyright {
    margin-top: 24px;
}
