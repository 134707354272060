.open-position__header {
    margin-bottom: 70px;
    padding-bottom: 70px;
    border-bottom: 1px solid var(--color-primary);
}

.open-position__header-actions {
    @include apply-screen-size(desktop-sm) {
        justify-content: flex-end;
        align-items: flex-end;
        max-width: 70%;
        margin-left: auto;
    }

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.open-position__header-social {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .social-links {
        flex: 0 0 auto;
        margin: 0;
        margin-left: 16px;
    }

    .social-links__list {
        margin: 0;
    }

    .social-links__item {
        margin-top: 0;
    }
}

.open-position__header-apply {
    @include apply-screen-size(desktop-sm) {
        margin-top: 24px;
    }

    margin-top: 16px;
}

.open-position__title {
    font-size: 22px;
    line-height: 24px;
}

.open-position__description {
    .columns--mobile-inverted .columns__item + .columns__item {
        margin-left: 0;
    }

    .button {
        margin-top: 30px;
    }
}
