.cookie-notification {
    position: fixed;
    z-index: 100;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    background: #fff;
    box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.cookie-notification__inner {
    display: flex;
    align-items: center;
    max-width: calc(var(--content-max-inner-width) + 2 * var(--content-section-padding-horizontal));
    margin: 0 auto;
    padding: 16px var(--content-section-padding-horizontal);
    color: var(--color-base);
}

.cookie-notification__title {
    display: block;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    font-family: $font-family-custom-book;
}

.cookie-notification__text {
    flex: 1 1 auto;
    font-size: 16px;
    line-height: 20px;
    font-family: $font-family-custom-book;
}

.cookie-notification__link {
    border-bottom: 1px solid currentColor;
    color: inherit;
    text-decoration: none;
    transition: color 0.1s, border-color 0.1s;

    &:hover {
        border-color: #fff;
        color: #333;
    }
}

.cookie-notification__close {
    cursor: pointer;

    &:hover {
        color: var(--color-primary);
    }
}

.cookie-notification__icon {
    color: inherit;
    pointer-events: none;
}

.cookie-notification__actions {
    display: flex;
    align-items: center;

    @media screen and (min-width: 768px) {
        margin-left: 16px;
    }
}

.cookie-notification__action {
    .button--primary.button--transparent {
        @include apply-screen-size(desktop-sm) {
            font-size: 16px;
            line-height: 18px;
        }
        @include apply-screen-size(desktop-md) {
            padding: 0 20px;
            font-size: 22px;
            line-height: 24px;
        }

        padding: 0 8px;
        font-size: 12px;
        line-height: 14px;
    }
}

// Relationship
//
.cookie-notification__title + .cookie-notification__text {
    margin-top: 4px;
}

.cookie-notification__text + .cookie-notification__close {
    margin-left: 16px;
}

.cookie-notification--cta {
    .cookie-notification__inner {
        @include apply-screen-size(desktop-sm) {
            flex-direction: row;
            justify-content: center;
            padding-top: 32px;
            padding-bottom: 32px;
        }

        flex-direction: column;
    }

    .cookie-notification__title {
        margin-top: 4px;
        font-size: 12px;
        line-height: 109%;
    }

    .cookie-notification__text {
        @include apply-screen-size(desktop-sm) {
            flex: 0 1 auto;
            opacity: 0.8;
            text-align: left;
            font-size: 14px;
        }
        @include apply-screen-size(desktop-xlg) {
            font-size: 18px;
        }

        opacity: 0.6;
        text-align: center;
        font-size: 12px;
        line-height: 109%;
    }

    .cookie-notification__title + .cookie-notification__text {
        margin-top: 8px;
    }

    .cookie-notification__actions {
        @include apply-screen-size(desktop-sm) {
            margin-top: 0;
        }

        margin-top: 10px;
    }
}
