@import '../_variables.scss';

.mobile-panel {
    --header-height: 50px;
    --logo-width: 75px;

    @include apply-screen-size(desktop-sm) {
        --header-height: 75px;
        --logo-width: 160px;

        z-index: var(--mobile-panel-z-index);
    }

    position: fixed;
    z-index: calc(var(--mobile-panel-z-index) + 1);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s ease opacity, 0.2s ease visibility;
    will-change: opacity, visibility;
}

.mobile-panel__inner {
    position: relative;
    z-index: calc(var(--mobile-panel-z-index) + 2);
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: var(--horizontal-gap-large);
    background-color: var(--menu-bakcground, #fff);

    @media screen and (min-width: $media-mobile) {
        width: 480px;
    }
}

.mobile-panel__backdrop {
    position: absolute;
    z-index: calc(var(--mobile-panel-z-index) + 1);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--color-base);
    opacity: 0.2;
}

.mobile-panel__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: var(--header-height);
    padding: 0 var(--horizontal-gap) 0;

    @media screen and (min-width: $media-mobile) {
        padding: 0 var(--horizontal-gap-large) 0;
    }
}

.mobile-panel__logo {
    color: var(--color-base);

    @media screen and (min-width: $media-mobile) {
        display: none;
    }
}

.mobile-panel__close {
    display: block;

    @media screen and (min-width: $media-mobile) {
        margin-left: auto;
    }
}

.mobile-panel__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 auto;
    padding: 0 var(--horizontal-gap);

    @media screen and (min-width: $media-mobile) {
        padding: 0 var(--horizontal-gap-large) 0;
    }
}

.mobile-panel__content-menu {
    display: block;
}

.mobile-panel__inner {
    transform: translate3d(100%, 0, 0);
}

.mobile-panel__download-app {
    display: flex;
    padding: 0 var(--horizontal-gap-large);
}

.mobile-panel__content-menu + .mobile-panel__content-menu {
    margin-top: 32px;
}

.mobile-panel__content + .mobile-panel__download-app {
    margin-top: var(--vertical-gap-large);
}

.is-opened-mobile-nav {
    overflow: hidden;
    height: 100vh;

    .mobile-panel {
        opacity: 1;
        visibility: visible;
    }

    .mobile-panel__inner {
        transform: translate3d(0, 0, 0);
        transition: 0.2s ease transform;
        will-change: transform;
    }
}

