.cta-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 24px 0;
}

.cta-box__media {
    display: block;

    & > img {
        vertical-align: top;
        width: 100%;
        height: auto;
    }
}

.cta-box__media-img {
    vertical-align: top;
    width: 100%;
    height: auto;
}

.cta-box__caption {
    display: block;
}

.cta-box__title {
    display: block;
}

.cta-box__title--centered {
    text-align: center;
}

.cta-box__subtitle {
    display: block;
}

.cta-box--centered {
    justify-content: center;
    text-align: center;
}

.cta-box--full {
    width: 100%;
    height: 100%;
    padding: 0;
}

.cta-box__media--centered {
    display: flex;
    justify-content: center;
}

.cta-box__media + .cta-box__caption,
.cta-box__media + .cta-box__title,
.cta-box__media + .cta-box__subtitle,
.cta-box__caption + .cta-box__media,
.cta-box__title + .cta-box__media,
.cta-box__title + .cta-box__title,
.cta-box__subtitle + .cta-box__media {
    margin-top: 25px;
}

.cta-box__media.cta-box__media--no-margin {
    margin-top: 0;
}

.cta-box__media + .cta-box__media {
    margin-top: 32px;
}

.cta-box__caption + .cta-box__title,
.cta-box__caption + .cta-box__subtitle {
    margin-top: 10px;
}

.cta-box__title + .cta-box__subtitle {
    margin-top: 10px;
}

.cta-box__subtitle + .cta-box__title {
    margin-top: 10px;
}
