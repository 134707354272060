html {
    color: var(--color-base);
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-base);
    line-height: var(--line-height-base);
    font-family: var(--font-family-book);
}

.theme-dating {
    --color-primary-light: #fff7d8;
    --color-primary: #ffc629;
    --color-primary-accent: #454650;
}
