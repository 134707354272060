.cards {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    margin: 0;
    padding: 0;
    // temporary
    padding-bottom: 12px;

    .page-section--slider & {
        &::after {
            @include apply-screen-size(desktop-sm) {
                width: var(--horizontal-gap-large);
            }

            display: block;
            width: var(--horizontal-gap);
            content: '';
        }
    }
}

.cards__item {
    display: flex;
    align-items: center;
    overflow: hidden;
    flex: 0 0 280px;
    min-width: 280px;
    border-radius: 12px;

    &[data-url] {
        cursor: pointer;
    }

    img {
        border-radius: 12px 12px 0 0;
    }
}

.cards__title {
    display: block;
    padding: 0 24px 38px;
    color: var(--color-base-deep);
    font-family: var(--font-family-bold);
}

.cards__text {
    display: block;
    padding: 0 24px 38px;
}

.cards__icon {
    display: flex;
}

.cards__item + .cards__item {
    margin-top: 0;
    margin-left: 24px;
}

.cards__icon + .cards__text {
    margin-left: 16px;
}

.cards__title + .cards__text {
    margin-top: -22px;
}

.cards--features {
    .cards__item {
        background-color: #fff7d8;
    }
}

.cards--howto {
    .cards__item {
        background-color: #e5f3ff;
    }
}
