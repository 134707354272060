// TODO: make dots loader with svg too
@import '../_variables.scss';

.loader {
    --ui-loader-dot-size: #{$ui-loader-dot-size-m};
    --ui-loader-gap-size: #{$ui-loader-gap-size-m};
    --ui-loader-logo-height: #{$ui-loader-logo-height-m};

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    margin: auto;
    color: inherit;
    fill: currentColor;
    transition: opacity 0.2s, color 0.4s;
    pointer-events: none;

    &.is-hidden {
        display: none;
    }
}

// Colors
.loader--primary {
    color: var(--color-primary);
    fill: var(--color-primary);
}

// Shape
@keyframes loader-dots-animation {
    0% {
        transform: scale(1);
    }

    25%,
    75% {
        transform: scale(0.5);
    }

    100% {
        transform: scale(1);
    }
}

.loader--dots {
    width: calc((var(--ui-loader-dot-size) * 3) + (var(--ui-loader-gap-size) * 2));
    height: var(--ui-loader-dot-size);

    &::before,
    &::after {
        content: '';

        // Fix for flex children display
        .ie11 & {
            display: block;
        }
    }

    &::before {
        animation: loader-dots-animation 1.2s ease-in-out infinite 0s;
    }

    &::after {
        animation: loader-dots-animation 1.2s ease-in-out infinite 0.2s;
    }

    &::before,
    &::after,
    .loader__inner {
        flex: 0 0 auto;
        width: var(--ui-loader-dot-size);
        height: var(--ui-loader-dot-size);
        border-radius: 100%;
        background: currentColor;
    }

    .loader__inner {
        margin-right: var(--ui-loader-gap-size);
        margin-left: var(--ui-loader-gap-size);
        animation: loader-dots-animation 1.2s ease-in-out infinite 0.1s;
    }
}

@keyframes loader-dots-jump-animation {
    0% {
        transform: translateY(0);
    }

    18% {
        transform: translateY(-100%);
    }

    36%,
    100% {
        transform: translateY(0);
    }
}

.loader--jumping-dots {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: calc((var(--ui-loader-dot-size) * 3) + (var(--ui-loader-gap-size) * 2));
    height: calc(var(--ui-loader-dot-size) * 2);
    color: $token-color-gray-dark;

    &::before,
    &::after {
        content: '';

        // Fix for flex children display
        .ie11 & {
            display: block;
        }
    }

    &::before {
        animation: loader-dots-jump-animation 1.5s ease-in-out infinite 0s;
    }

    &::after {
        animation: loader-dots-jump-animation 1.5s ease-in-out infinite 0.2s;
    }

    &::before,
    &::after,
    .loader__inner {
        flex: 0 0 auto;
        width: var(--ui-loader-dot-size);
        height: var(--ui-loader-dot-size);
        border-radius: 100%;
        background: currentColor;
    }

    .loader__inner {
        margin-right: var(--ui-loader-gap-size);
        margin-left: var(--ui-loader-gap-size);
        animation: loader-dots-jump-animation 1.5s ease-in-out infinite 0.1s;
    }
}

@keyframes loader-logo-animation {
    0% {
        transform: scaleX(1);
    }

    16.6% {
        transform: scaleX(1.3);
    }

    33.3% {
        transform: scaleX(1);
    }

    100% {
        transform: scaleX(1);
    }
}

.loader--logo {
    > svg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        height: var(--ui-loader-logo-height);
        margin: auto;
        color: inherit;
        fill: currentColor;
        transition: opacity 0.2s, color 0.4s;
        pointer-events: none;
    }

    .loader-logo__item {
        transform-origin: 50% 50%;
        animation: 2s ease-in-out infinite;
    }

    .loader-logo__item--1 {
        animation-delay: 0s;
    }

    .loader-logo__item:nth-child(2) {
        animation-delay: 0.2s;
    }

    .loader-logo__item:nth-child(3) {
        animation-delay: 0.4s;
    }

    &.is-animated {
        .loader-logo__item {
            animation-name: loader-logo-animation;
        }
    }
}

// Sizes
.loader--size-m {
    --ui-loader-dot-size: #{$ui-loader-dot-size-m};
    --ui-loader-gap-size: #{$ui-loader-gap-size-m};
    --ui-loader-logo-height: #{$ui-loader-logo-height-m};
}

.loader--size-s {
    --ui-loader-dot-size: #{$ui-loader-dot-size-s};
    --ui-loader-gap-size: #{$ui-loader-gap-size-s};
    --ui-loader-logo-height: #{$ui-loader-logo-height-s};
}

.loader--size-l {
    --ui-loader-dot-size: #{$ui-loader-dot-size-l};
    --ui-loader-gap-size: #{$ui-loader-gap-size-l};
    --ui-loader-logo-height: #{$ui-loader-logo-height-l};
}
