.button-group {
    display: flex;
}

.button-group__item + .button-group__item {
    margin-left: 16px;
}

.button-group--sm {
    .button-group__item + .button-group__item {
        margin-left: 8px;
    }
}

.button-group--lg {
    .button-group__item + .button-group__item {
        margin-left: 40px;
    }
}

.button-group--scroll {
    overflow: auto;
    width: 100%;
    padding-bottom: 12px;
}
