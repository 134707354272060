.list-icon-text {
    padding: 0;
}

.list-icon-text__item {
    display: flex;
    align-items: center;
}

.list-icon-text__title {
    margin-bottom: 8px;
    color: var(--color-base-deep);
    font-family: var(--font-family-bold);
}

.list-icon-text__icon {
    display: flex;

    // trick for iframes
    img {
        z-index: -1;
    }
}

.list-icon-text__item + .list-icon-text__item {
    margin-top: 8px;
}

.list-icon-text__icon + .list-icon-text__text {
    margin-left: 16px;
}
