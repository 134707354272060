@mixin apply-sticky-effect($top: 16px) {
    &.is-sticky {
        position: sticky;
        top: $top;
        transition: transform 400ms;

        .has-sticky-header.is-initiated & {
            transform: translateY(var(--header-height));
        }

        .ios &,
        .android & {
            position: relative;
            top: auto;
            transition: none;

            // complicated case, when we want to have sticky on mobile
            &.has-mobile-sticky {
                position: sticky;
                top: $top;
                transition: transform 400ms;
            }
        }

        .ios .has-sticky-header.is-initiated &,
        .android .has-sticky-header.is-initiated & {
            transform: none;

            &.has-mobile-sticky {
                transform: translateY(var(--header-height));
            }
        }
    }
}
@mixin apply-brand-link-hover-state($bottom : -14px, $color : var(--color-base)) {
    position: relative;

    &::after {
        position: absolute;
        bottom: $bottom;
        left: 0;
        width: 100%;
        height: 2px;
        content: '';
        background-color: $color;
        opacity: 1;
        transform: scale(0, 1);
        transform-origin: left bottom;
        transition: 400ms transform ease-in-out;
    }

    &:hover,
    &:active {
        opacity: 1;

        &::after {
            transform: scale(1, 1);
        }
    }
}
@mixin apply-link-block {
    display: block;
    color: inherit;
    text-decoration: none;
    transition: 0.2s ease opacity;
}
@mixin apply-link-block-hovered() {
    &:hover {
        opacity: 0.8;
    }
}
@mixin apply-link-custom-underlined() {
    border-bottom: 1px solid;
    color: var(--color-grey);
    text-decoration: none;
    transition: 0.2s ease color;
}
@mixin apply-link-custom-underlined-hovered() {
    color: var(--color-base);
    opacity: 1; // todo: put hovers inside all mixins
}
@mixin apply-link-decorated() {
    display: inline;
    background-image: linear-gradient(to right, var(--color-primary) 0%, var(--color-primary) 100%);
    background-position: 0 96%;
    background-repeat: repeat-x;
    background-size: 100% 2px;
    transition: 0.1s ease background-position, 0.1s ease opacity;
}
@mixin apply-link-decorated-hovered() {
    background-position: 0 106%;
    opacity: 0.8;
}
@mixin apply-typography-title-second-level() {
    font-weight: var(--font-weight-medium);
    font-size: 20px;
    line-height: 28px;

    @media screen and (min-width: $media-tablet) {
        font-size: 22px;
        line-height: 28px;
    }
    @media screen and (min-width: $media-desktop) {
        font-size: 24px;
        line-height: 32px;
    }
}
@mixin apply-typography-title-zero-level() {
    font-weight: var(--font-weight-bold);
    font-size: 32px;
    line-height: 40px;

    @media screen and (min-width: $media-tablet) {
        font-size: 44px;
        line-height: 56px;
    }
    @media screen and (min-width: $media-desktop) {
        font-size: 48px;
        line-height: 64px;
    }
}
@mixin apply-typography-title-third-level() {
    font-weight: var(--font-weight-medium);
    font-size: 18px;
    line-height: 24px;

    @media screen and (min-width: $media-desktop) {
        font-size: 20px;
        line-height: 28px;
    }
}
@mixin apply-typography-title-fourth-level() {
    font-weight: var(--font-weight-medium);
    font-size: 16px;
    line-height: 24px;

    @media screen and (min-width: $media-tablet) {
        font-size: 16px;
        line-height: 24px;
    }
    @media screen and (min-width: $media-desktop) {
        font-size: 16px;
        line-height: 24px;
    }
}
@mixin apply-typography-navigation-button() {
    color: var(--color-grey);
    font-size: 16px;
    line-height: 24px;
    transition: 0.2s opacity ease-in;

    &:hover {
        color: var(--color-base);
    }
}
@mixin apply-respond-to($breakpoint) {
    @if $breakpoint == retina {
        @media only screen and (-webkit-min-device-pixel-ratio: 1.25),
            only screen and (min-device-pixel-ratio: 1.25),
            only screen and (min-resolution: 200dpi),
            only screen and (min-resolution: 1.25dppx) {
            @content;
        }
    }

    @else {
        @media screen and (max-width: $breakpoint) {
            @content;
        }
    }
}
