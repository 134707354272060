.link {
    display: inline;
    color: var(--color-primary);
    text-decoration: none;
    font-weight: var(--font-weight-medium);
    transition: 0.2s ease opacity;
}

.link--decorated {
    @include apply-link-decorated();
}

.link--underlined {
    @include apply-link-custom-underlined();
}

.link--white {
    --color-primary: #fff;
}

@media screen and (min-width: $media-desktop) {
    .link:hover {
        opacity: 0.8;
    }

    .link--decorated:hover {
        @include apply-link-decorated-hovered();
    }

    .link--underlined:hover {
        @include apply-link-custom-underlined-hovered();
    }
}
