.connections-map {
    width: 100%;
    background: var(--color-base);
    padding: var(--content-section-padding-vertical) 0;
}

.connections-map__title {
    margin-bottom: 48px;
    font-family: $font-family-custom-black;
    font-weight: 900; // Black
    font-size: 30px;
    line-height: 1.09;
    color: #fff;
    text-align: center;
}

.connections-map__media {
    position: relative;
    width: 100%;
    max-width: 960px;
    margin: 0 auto;

    &::before {
        content: '';
        width: 100%;
        display: block;
        padding-bottom: calc(1 / var(--ratio) * 100%);
    }
}

.connections-map__video,
.connections-map__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.connections-map--spacious {
    .connections-map__title {
        margin-bottom: 120px;
        font-size: 60px;
    }
}
