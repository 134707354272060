.benefits-office-cards {
    flex: 1 1 auto;
    min-width: 0;
}

.benefits-office-cards__inner {
    @include apply-screen-size(desktop-sm) {
        --card-gap: 20px;
    }

    @include apply-screen-size(desktop-md) {
        --resize-speed: 0.7;

        grid-template-columns: 1fr 1fr 1fr;
    }

    flex: 1 1 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(clamp(140px, 20%, 320px), 1fr));
    grid-gap: var(--card-gap, 10px);
    min-width: 0;
}

.benefits-office-card {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-radius: 25px;
    box-shadow: 0 64.57px 54.37px rgba(0, 0, 0, 0.05), 0 62px 62px rgba(0, 0, 0, 0.07),
        0 15px 17px rgba(0, 0, 0, 0.07), 0 7px 14px rgba(0, 0, 0, 0.1),
        0 29px 38px rgba(0, 0, 0, 0.03), 0 4px 12px rgba(0, 0, 0, 0.07),
        0 4px 4px rgba(0, 0, 0, 0.05), 0 14px 11px rgba(0, 0, 0, 0.05);
    color: #fff;
}

.benefits-office-card__back {
    position: relative;
}

.benefits-office-card__front {
    @include apply-screen-size(desktop-sm) {
        padding: 40px 0;
    }

    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.01) 0%, rgba(0, 0, 0, 0.4) 100%);
    padding: 20px 0;
    opacity: 1;
}

.benefits-office-card__title {
    position: relative;
    text-align: center;
    margin: auto;
    width: 100%;
    word-wrap: break-word;

    .h3 {
        color: #fff;
    }
}

.benefits-office-card__soon {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    font-size: max(14px, calc(0.5 * var(--font-size)));
    margin-top: 5px;
}

.benefits-office-card__text {
    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
        color: #fff;
        font-family: var(--font-family-black, 'Circular20WebCyr-Black');
    }
}

.benefits-office-card__icon {
    margin-bottom: 30px;
    position: relative;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    transition: background-color .2s, color .2s;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        border: 1px solid #fff;
        border-radius: 100%;
    }

    .benefits-office-card:hover & {
        color: var(--color-base);
        background-color: #fff;
    }
}

.benefits-office-cards--two {
    @include apply-screen-size(desktop-md) {
        .benefits-office-cards__inner {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .benefits-office-card {
            width: calc(33% - 20px / 3);
        }
    }
}
