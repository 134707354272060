.cta-app-button-wrapper {
    display: block;
}

.cta-app-button {
    display: block;

    .icon {
        display: block;
    }
}

.ios .cta-app-button .icon {
    display: block;
}
