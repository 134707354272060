.media-card {
    @include apply-screen-size(tablet-md) {
        --media-card-border-radius: 25px;
    }

    position: relative;
    overflow: hidden;
    border-radius: var(--media-card-border-radius, 10px);

    &.has-shadow {
        box-shadow:
            0 64.57px 54.37px rgba(0, 0, 0, 0.05),
            0 62px 62px rgba(0, 0, 0, 0.07),
            0 15px 17px rgba(0, 0, 0, 0.07),
            0 7px 14px rgba(0, 0, 0, 0.1),
            0 29px 38px rgba(0, 0, 0, 0.03),
            0 4px 12px rgba(0, 0, 0, 0.07),
            0 4px 4px rgba(0, 0, 0, 0.05),
            0 14px 11px rgba(0, 0, 0, 0.05);
    }
}

.media-card__back {
    position: relative;
}

.media-card__back-image {
    display: block;
    overflow: hidden;
    border-radius: var(--media-card-border-radius);
    background-color: var(--color-grey-light);
}

.media-card__front {
    @include apply-screen-size(desktop-sm) {
        padding: 32px;
    }

    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 16px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.01) 0%, rgba(0, 0, 0, 0.4) 100%);
    opacity: 0;

    &:hover {
        opacity: 1;
        transition: opacity 200ms ease-in-out;
    }
}

.media-card__front-title {
    font-size: 25px;
    line-height: 34px;

    .h3 {
        color: #fff;
    }
}

.media-card__front-text {
    font-size: 14px;
    line-height: 20px;

    .h4 {
        color: #fff;
    }
}

.media-card__front-title + .media-card__front-text {
    margin-top: 8px;
}
