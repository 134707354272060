// grid technique allows place all the internal block one over another without position: absolute
// which let us have a shape based on aspect-ratio (and pseudo-element) but also avoid text overflow for extreme cases
@import '../typography/typography.reference.scss';

.column-media-card {
    position: relative;
    display: grid;
    overflow: hidden;
    width: 100%;
    min-height: inherit;
    max-height: inherit;
    border-radius: 15px;

    & > * {
        grid-area: 1 / 1 / 2 / 2;
    }

    &::before {
        display: block;
        grid-area: 1 / 1 / 2 / 2;
        padding-bottom: calc(100% / var(--aspect-ratio));
        content: '';
    }

    &.has-shadow {
        box-shadow:
            0 64.57px 54.37px rgba(0, 0, 0, 0.05),
            0 62px 62px rgba(0, 0, 0, 0.07),
            0 15px 17px rgba(0, 0, 0, 0.07),
            0 7px 14px rgba(0, 0, 0, 0.1),
            0 29px 38px rgba(0, 0, 0, 0.03),
            0 4px 12px rgba(0, 0, 0, 0.07),
            0 4px 4px rgba(0, 0, 0, 0.05),
            0 14px 11px rgba(0, 0, 0, 0.05);
    }
}

.column-media-card__overlay {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 0;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.3);
}

.column-media-card__title {
    @include apply-responsive-font-size('header-2', true);

    min-width: 0;
    color: #fff;
    word-wrap: break-word;
    text-align: center;
    font-weight: 700; // Bold
    font-family: $font-family-custom-bold;
}
