// TODO: ways to improve
// 1) there should be one custom font named 'Circular', boldness should be changed by font-weight, not font-family
// 2) I'm not sure how standard the typography is, but ideally if typography is changed between desktop and mobile,
// we should either rerender whole component (use P2 instead of P3 for example), or use custom typography
// and change font-size (maybe line-height too) via css variables
// 3) box-shadow around the photo - to mixins, it's reused widely
// 4) proper gaps for desktop and mobile versions. Better use native variables (i.e. --page-gap-vertical), that are redefined for desktop devices
// 5) we should stop mixing JS media queries and css - only one approach should be used widely

.values-carousel {
    position: relative;
    // max-width: 100%;
    width: 100%;
    // min-height: inherit;
    // max-height: 100%;
    height: 100%;
    // padding: var(--content-section-padding-vertical) var(--content-section-padding-horizontal);
    background: var(--color-primary);

    // @todo add proper color definition
    .caption {
        color: var(--color-base);
    }
}

.values-carousel--spacious {
    .values-carousel__title {
        font-size: 20px;
    }
}

.values-carousel__title {
    // position: absolute;
    // top: calc(var(--content-section-padding-vertical) / 2);
    // left: 0;
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    font-weight: 900; // Black
    font-size: 14px;
    line-height: 1;
    font-family: $font-family-custom-black;
    // transform: translateY(-50%);
}

.values-carousel__card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    max-width: 1180px;
    width: calc(100% - 4px); // visual alignment
    min-height: 67vh;
    max-height: 430px;
    height: 100%;
    margin: auto;
    padding: 20px;
    border-radius: 25px;
    background: #fff;
    color: var(--color-base);
    text-align: center;

    @media screen and (min-height: 800px) {
        min-height: 608px;
        max-height: auto;
    }
}

.values-carousel__title + .values-carousel__cards {
    @include apply-screen-size(tablet-md ) {
        margin-top: 66px;
    }

    margin-top: 32px;
}

.values-carousel__card--spacious {
    padding: 60px;

    .values-carousel__symbol {
        width: 192px;
        height: 192px;
    }

    .values-carousel__card-title {
        margin-bottom: 36px;
        font-size: 50px;
        font-family: $font-family-custom-bold;
    }

    .values-carousel__card-description {
        max-width: 700px;
        font-size: 22px;
        line-height: 22px;
    }

    .values-carousel__card-close {
        top: 0;
        right: 0;
        box-sizing: border-box;
        width: 99px;
        height: 99px;
        padding: 32px;
    }
}

.values-carousel__symbol {
    position: relative;
    width: 132px;
    height: 132px;
    margin: 0 auto 8px;
}

.values-carousel__card-title {
    margin-bottom: 12px;
    font-weight: 700; // Bold
    font-size: 30px;
    line-height: 1.09;
    font-family: $font-family-custom-bold;
}

.values-carousel__card-description {
    font-weight: 400; // Book
    font-size: 14px;
    line-height: 18px;
    font-family: $font-family-custom-book;
}

.values-carousel__card-close {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 16px;
    height: 16px;
    padding: 0;
    background: transparent;
    color: #c4c4c4;
    transition: color 0.3s;
    appearance: none;
    cursor: pointer;

    &:hover {
        color: var(--color-base);
    }
}
