$layout-max-width: 1680px;
$stats-text-margin-top: 20px;

// HORIZONTAL GAP
//

$layout-mw-gap: 20px;
$layout-dw-gap: 70px;

// VERTICAL GAP
//

// gap
$layout-mw-vertical-gap: 25px;
$layout-dw-vertical-gap: 50px;

// md
$layout-mw-vertical-gap-md: 40px;
$layout-dw-vertical-gap-md: 80px;

// lg
$layout-mw-vertical-gap-lg: 50px;
$layout-dw-vertical-gap-lg: 100px;

// xlg
$layout-mw-vertical-gap-xlg: 65px;
$layout-dw-vertical-gap-xlg: 130px;

// map
$layout-vertical-gap-map: (
    mw: (
        gap: $layout-mw-vertical-gap,
        md: $layout-mw-vertical-gap-md,
        lg: $layout-mw-vertical-gap-lg,
        xlg: $layout-mw-vertical-gap-xlg
    ),
    dw: (
        gap: $layout-dw-vertical-gap,
        md: $layout-dw-vertical-gap-md,
        lg: $layout-dw-vertical-gap-lg,
        xlg: $layout-dw-vertical-gap-xlg
    )
);

// UTILS
//

@mixin apply-screen-size($size) {
    @if $size == desktop-xlg {
        @media (min-width: 1440px) {
            @content;
        }
    }
    @if $size == desktop-lg {
        @media (min-width: 1360px) {
            @content;
        }
    }

    @else if $size == desktop-md {
        @media (min-width: 1024px) {
            @content;
        }
    }
    @else if $size == desktop-sm {
        @media (min-width: 640px) {
            @content;
        }
    }
    @else if $size == tablet-lg {
        @media (min-width: 990px) {
            @content;
        }
    }
    @else if $size == tablet-md {
        @media (min-width: 900px) {
            @content;
        }
    }
    @else if $size == phone-xlg {
        @media (min-width: 480px) {
            @content;
        }
    }
    @else if $size == phone-lg {
        @media (min-width: 412px) {
            @content;
        }
    }
    @else if $size == phone-md {
        @media (min-width: 376px) {
            @content;
        }
    }
}
