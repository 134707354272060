.success-stories {
    --success-card-gap: 16px;

    @include apply-screen-size(desktop-md) {
        padding-bottom: 150vh;
        transform: translateY(9%);
    }

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1360px;
    width: 100%;
}

.success-stories__item {
    @include apply-screen-size(desktop-sm) {
        margin-bottom: 40px;
    }
    @include apply-screen-size(desktop-md) {
        &:first-child {
            transform: translate3d(35%, -40%, 0);
        }

        &:nth-child(2) {
            transform: translate3d(130%, -6%, 0);
        }

        &:nth-child(3) {
            transform: translate3d(-210%, 85%, 0);
        }

        &:nth-child(4) {
            transform: translate3d(258%, 12%, 0);
        }

        &:nth-child(5) {
            transform: translate3d(23%, 50%, 0);
        }

        &:nth-child(6) {
            transform: translate3d(-263%, 101%, 0);
        }

        &:nth-child(7) {
            transform: translate3d(28%, 70%, 0);
        }

        &:nth-child(8) {
            transform: translate3d(55%, 95%, 0);
        }
    }
    @include apply-screen-size(desktop-lg) {
        max-width: 346px;
    }

    flex: 0 1 calc(50% - (var(--success-card-gap) / 2));
    max-width: 320px;
    width: calc(50% - (var(--success-card-gap) / 2));
}

.success-stories__item + .success-stories__item {
    @include apply-screen-size(desktop-sm) {
        margin-top: 0;
    }

    margin-top: 40px;
    margin-left: var(--success-card-gap);

    &:nth-child(2n + 1) {
        @include apply-screen-size(tablet-lg) {
            margin-left: var(--success-card-gap);
        }

        margin-left: 0;
    }

    &:nth-child(2n + 2) {
        @include apply-screen-size(desktop-sm) {
            margin-top: 0;
        }

        margin-top: 20px;
    }
}
