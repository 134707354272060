$columns-gap: 64px;
$columns-gap-for-two: $columns-gap * 0.5;

.columns {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 100%;
}

.columns__item {
    // @todo: probably rethink later with concepts of listeners
    @include apply-sticky-effect();

    display: block;
    width: 100%;

    // TODO: link the value to the grid sizes
    // VERY BAD TEMP SOLUTION - styling inner blocks depending on layout
    &:only-child {
        max-width: 675px;
        margin: 0 auto;
        text-align: center;
    }

    // TODO: check - probably it's not CTA box here
    .cta-box {
        padding: 0;
    }
}

.columns__item + .columns__item {
    margin-left: var(--columns-item-gap, $columns-gap);
}

.columns__item--one-third {
    flex: 0 0 calc(33.3% - var(--columns-item-gap, #{$columns-gap-for-two}));
}

.columns__item--two-third {
    flex: 0 1 calc(66.6% - var(--columns-item-gap, #{$columns-gap-for-two}));
}

.columns__item--center {
    display: flex;
    justify-content: center;
    text-align: center;
}

.columns--align-top {
    align-items: flex-start;
}

.columns__item--within {
    overflow: hidden;
}

.columns--align-bottom {
    align-items: flex-end;
}

.columns--mobile {
    @include apply-screen-size(desktop-sm) {
        flex-direction: row;
    }

    flex-direction: column;

    .columns__item + .columns__item {
        @include apply-screen-size(desktop-sm) {
            margin-top: 0;
            margin-left: var(--columns-item-gap, $columns-gap);
        }

        margin-top: var(--columns-item-gap, 40px);
        margin-left: 0;
    }
}

.columns--mobile-inverted {
    @include apply-screen-size(desktop-sm) {
        display: flex;
        flex-direction: row-reverse;
    }

    display: block;

    .columns__item {
        flex: 0 1 100%;
        width: 100%;
    }

    .columns__item + .columns__item {
        @include apply-screen-size(desktop-sm) {
            margin-top: 0;
            margin-right: var(--columns-item-gap, 64px);
        }

        margin-top: var(--columns-item-gap, 32px);
        margin-left: 0;
    }
}

.columns--equal {
    .columns__item {
        @include apply-screen-size(desktop-sm) {
            flex: 0 1 50%;
            width: 50%;
        }
    }
}
