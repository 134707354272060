.action-buttons {
    width: 100%;
    padding: 0;

    @media screen and (min-width: $media-desktop) {
        display: flex;
    }
}

.action-buttons__item {
    display: flex;
    align-items: center;
    flex: 1 1 50%;
    width: 100%;
    padding: 24px;
    border-radius: 16px;
    background-color: #f6f6f6;

    &.is-active {
        cursor: pointer;
    }
}

.action-buttons__icon {
    display: flex;
    width: 48px;
    height: 48px;
}

.action-buttons__title {
    color: var(--color-base-deep);
    letter-spacing: -0.3px;
    font-family: var(--font-family-bold);
}

.action-buttons__text {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.action-buttons__description {
    font-family: var(--font-family-book);

    a {
        color: inherit;
    }
}

.action-buttons__item + .action-buttons__item {
    margin-top: 16px;

    @media screen and (min-width: $media-desktop) {
        margin-top: 0;
        margin-left: 24px;
    }
}

.action-buttons__icon + .action-buttons__text {
    margin-left: 16px;
}

.action-buttons__title + .action-buttons__description {
    margin-top: 8px;
}
