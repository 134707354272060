.header {
    --header-height: 50px;

    @include apply-screen-size(desktop-sm) {
        --header-height: 75px;
    }

    height: var(--header-height);
    background: var(--header-bg-color, #fff);
}

.header__inner {
    z-index: var(--header-z-index);
    display: flex;
    align-items: center;
    height: inherit;
    padding: 0 12px 0 16px;
    background: inherit;
    transition: 0.2s transform;

    &.is-fixed {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
    }

    &.is-hidden {
        transform: translate3d(0, -100%, 0);
    }

    &.has-shadow {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .is-opened-mobile-nav & {
        z-index: calc(var(--mobile-panel-z-index) + 1);
    }

    @media screen and (min-width: 600px) {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
    }
}

.header__menu {
    display: none;
}

.header__actions {
    display: flex;
    align-items: center;

    .header__inner.has-actions-v-aligned & {
        margin-top: 23px; // v-align hack
    }
}

.header__logotype {
    display: flex;
    flex: 1 0 auto;
    align-self: center;
}

.header__logotype-link {
    --logo-width: 75px;

    display: inline-block;
    vertical-align: middle;
    text-decoration: none;

    .logotype {
        color: var(--color-primary);
    }
}

.header__actions-item--dw-buttons {
    display: none;
}

.header__actions-item--download-ios {
    display: none;

    .ios & {
        display: block;
    }
}

.header__actions-item--download-android {
    display: none;

    .android & {
        display: block;
    }
}

.header__actions-item + .header__actions-item {
    margin-left: 20px;
}

.header__logotype + .header__actions {
    margin-left: auto;
}

@media screen and (min-width: $media-desktop) {
    .header__inner {
        padding-right: 52px;
        padding-left: 32px;
    }

    .header__logotype {
        flex: 0 0 auto;
        align-self: flex-end;
        padding-bottom: 19px;
    }

    .header__logotype-link {
        --logo-width: 160px;

    }

    .header__menu {
        display: block;
        flex: 1 0 auto;
        margin-left: 48px;
    }
}
