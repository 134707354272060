.language-selector {
    position: relative;
}

.language-selector__label {
    display: flex;
    align-items: center;
    cursor: pointer;

    &::after {
        display: block;
        margin-bottom: -1px;
        margin-left: 4px;
        content: '';
        border: solid transparent;
        border-width: 6px 3px 0 3px;
        border-top-color: currentColor;
    }
}

.language-selector__trigger {
    display: none;
}
