@import '../_variables.scss';

@keyframes video-zoom-in-animation {
    0% {
        transform: scale(0.1);
    }

    100% {
        transform: scale(1);
    }
}

.media-box-wrapper {
    position: relative;
}

.media-box__text {
    text-align: center;
    font-size: 18px;
    line-height: 1.3;
}

.media-box__caption {
    color: var(--color-grey);
}

.media-box__text,
.media-box__caption {
    padding: 0 var(--horizontal-gap);
}

.media-box__media {
    position: relative;
    overflow: hidden;
    width: inherit;
}

.media-box__media-image {
    --aspect-ratio: (9 / 16);

    display: block;
    vertical-align: top;
    max-width: 100%;
    width: auto;

    &[src=''] {
        width: 100%;
        padding-top: 65%;
    }
}

.media-box__media-iframe {
    --aspect-ratio: (9 / 16);

    width: 100%;
    padding-top: calc(var(--aspect-ratio) * 100%);

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.media-box__text + .media-box__caption,
.media-box__media + .media-box__caption {
    margin-top: 12px;
}

@include apply-screen-size(desktop-sm) {
    .media-box__text {
        font-size: 22px;
    }

    .media-box__media-iframe {
        height: 250px;
    }

    .media-box__text + .media-box__caption,
    .media-box__media + .media-box__caption {
        margin-top: 16px;
    }

    .media-box__text,
    .media-box__caption {
        padding: 0 var(--horizontal-gap-large);
    }
}

@media screen and (min-width: $media-desktop) {
    .media-box__media-iframe {
        height: 325px;
    }

    .media-box__text {
        font-size: 28px;
    }
}

.media-box {
    --aspect-ratio: 1;
    --media-box-width: auto;
    --media-box-height: auto;
    --media-box-picture-radius: 0;

    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1 0 auto;
    max-width: var(--media-box-width);
    width: 100%;
    height: var(--media-box-height);
    margin: 0;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    user-select: none;
}

/*
* We are using cover for images so we want
* image to be a bit larger than parent container
* to avoid subpixel wholes on HD-screens
*/

.media-box__picture {
    @include apply-respond-to(retina) {
        top: -2px;
        left: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
    }

    top: -1px;
    left: -1px;
    display: flex;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    background-color: var(--color-primary);
    opacity: 1;
    transition: filter 0.2s, opacity 0.1s;

    &::before {
        display: block;
        width: 100%;
        padding-top: calc(var(--aspect-ratio) * 100%);
        content: '';
    }

    .badoo & {
        --color-primary: #783cfa;
    }
}

.media-box__picture-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.media-box__placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-base);
}

.media-box__video {
    @include apply-respond-to(retina) {
        top: -2px;
        left: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
    }

    top: -1px;
    left: -1px;
    display: flex;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    opacity: 1;
    transition: filter 0.2s, opacity 0.1s;

    &::before {
        display: block;
        padding-top: calc(var(--aspect-ratio) * 100%);
        content: '';
    }
}

.media-box__video-preview {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    pointer-events: auto;
}

img.media-box__video-preview {
    object-fit: cover;
}

.media-box-external-video,
.media-box__video-external--preview {
    position: fixed;
    z-index: 101; // for now
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    visibility: hidden;
    transform: scale(0);
    transition: transform 200ms ease-in-out;
    pointer-events: none;

    &.is-inlined {
        position: relative;
    }
}

.media-box-video-close {
    position: fixed;
    top: 72px;
    right: 24px;
    width: 48px;
    height: 48px;
    visibility: hidden;
    transform: scale(0);
    transition: transform 200ms ease-in-out 200ms;
    cursor: pointer;

    > svg {
        pointer-events: none;
    }
}

.media-box__video-play-button-title {
    display: none;

    @media screen and (min-width: $media-desktop) {
        display: block;
    }
}

.media-box__video-play-button-icon {
    width: 64px;
    height: 64px;
}

.media-box__video-play-button-title + .media-box__video-play-button-icon {
    @media screen and (min-width: $media-desktop) {
        margin-top: 24px;
    }
}

.media-box__video-play-button.media-box__video-play-button--with-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 380px;
    width: calc(100% - 64px);
    text-align: center;
}

.media-box.has-open-animation {
    &.is-playing {
        & + .media-box-external-video + .media-box-video-close,
        & + .media-box-external-video,
        .media-box__video-close,
        .media-box__video-external--preview {
            z-index: 102; // for now
            border-radius: 0;
            visibility: visible;
            transform: scale(1);
            pointer-events: auto;
        }

        .media-box__video-play-button {
            display: none;
        }
    }

    .media-box__video-close {
        position: fixed;
        top: 72px;
        right: 24px;
        width: 48px;
        height: 48px;
        visibility: hidden;
        transform: scale(0);
        transition: transform 200ms ease-in-out 200ms;
        cursor: pointer;

        > svg {
            pointer-events: none;
        }
    }
}

.media-box:not(.has-open-animation).is-playing {
    & + .media-box-external-video.is-mobile {
        visibility: visible;
        transform: scale(1);
        pointer-events: auto;
    }

    .android &,
    .ios & {
        & + .media-box-external-video.is-mobile + .media-box-video-close {
            visibility: hidden;
        }
    }
}

.media-box-wrapper {
    .media-box:not(.has-open-animation) + .media-box-external-video.is-mobile {
        position: absolute;
        visibility: visible;
        transform: scale(1);
        pointer-events: auto;
    }
}

.media-box__video-play-button {
    $media-box-play-icon-size: 64px;

    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: center;
    width: $media-box-play-icon-size;
    height: $media-box-play-icon-size;
    color: #fff;
    transform: translate(-50%, -50%);
    pointer-events: none;

    &.is-hidden {
        display: none;
    }
}

.media-box__video-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: center;
    max-width: 100px;
    width: 50%;
    max-height: 100px;
    height: 50%;
    color: #fff;
    transform: translate(-50%, -50%);
    pointer-events: none;
}

.aspect--portrait {
    --aspect-ratio: (4 / 3);
    --media-box-width: 360px;
}

.aspect--landscape {
    --aspect-ratio: (3 / 4);
    --media-box-width: 400px;
}

.aspect--wide-landscape {
    --aspect-ratio: (1.85 / 3);
}

.aspect--square {
    --aspect-ratio: 1;
}

.aspect--phone {
    --aspect-ratio: 1.76;
}

.aspect--phone-lg {
    --aspect-ratio: 2.07;
}

.aspect--benefits-card {
    --aspect-ratio: 1.48;
}

.aspect--free {
    width: 100%;
    height: 100%;

    .media-box__picture {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        &::before {
            content: none;
        }
    }
}

.aspect--none {
    .media-box__picture::before {
        content: none;
    }

    .media-box__picture-image {
        position: relative;
    }
}

.media-box.is-fluid {
    --media-box-width: 100%;
}

.media-box.has-shadow {
    border-radius: 10px;
    box-shadow:
        0 2.767px 2.213px 0 rgba(0, 0, 0, 0.02),
        0 6.65px 5.32px 0 rgba(0, 0, 0, 0.03),
        0 12.521px 10px 0 rgba(0, 0, 0, 0.04),
        0 22.336px 17.869px 0 rgba(0, 0, 0, 0.04),
        0 41.777px 33.422px 0 rgba(0, 0, 0, 0.05),
        0 100px 80px 0 rgba(0, 0, 0, 0.07);
}

.media-box.has-extra-shadow {
    border-radius: 16px;
    box-shadow:
        0 64.5683px 54.3733px rgba(0, 0, 0, 0.15),
        0 32.3157px 27.2132px rgba(0, 0, 0, 0.11405),
        0 19.4654px 16.392px rgba(0, 0, 0, 0.097738),
        0 12.4745px 10.5048px rgba(0, 0, 0, 0.085634),
        0 8.08495px 6.80838px rgba(0, 0, 0, 0.075),
        0 5.08938px 4.2858px rgba(0, 0, 0, 0.064366),
        0 2.92417px 2.46246px rgba(0, 0, 0, 0.052262),
        0 1.287px 1.08379px rgba(0, 0, 0, 0.03595);
}

.media-box-youtube {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media screen and (max-width: $media-desktop) and (orientation: landscape) {
        transform: scale(0.8);
    }
}

.media-box-youtube__frame {
    width: 100%;
    height: 100%;
    border-radius: 16px;
}
