.apps-links {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
}

// links in CTA boxes in hero blocks
.apps-links--default {
    --item-size: 32px;
    --item-gap: 8px;

    &.apps-links--spacious {
        --item-size: 44px;
        --item-gap: 10px;
    }
}

// links in the footer
.apps-links--compact {
    --item-size: 30px;
    --item-gap: 8px;
    width: fit-content;

    &.apps-links--spacious {
        --item-size: 32px;
    }
}

.apps-links__item {
    & + & {
        margin-left: var(--item-gap);
    }
}

.apps-links__link {
    @include apply-link-block();
    width: var(--item-size);
    height: var(--item-size);
}

.apps-links__link--color-primary {
    color: var(--color-primary);
}

.apps-links__link--color-base {
    color: var(--color-base);
}

.apps-links__link--color-bumble {
    color: #ffc629;
}

.apps-links__link--color-badoo {
    color: #783bf9;
}
