.video-carousel {
    position: relative;
    overflow-x: hidden;
}

.video-carousel__list {
    display: flex;
    align-items: center;
    width: 100%;
}

.video-carousel__card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    min-height: inherit;
    padding: 0 var(--arrow-size);
}

.video-carousel__action {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.video-carousel__play-button {
    --video-play-button-size: 32px;

    @include apply-screen-size(desktop-md) {
        --video-play-button-size: 80px;
    }

    width: var(--video-play-button-size);
    height: var(--video-play-button-size);
}

.video-carousel__card--spacious {
    flex-direction: row;
    align-items: center;
}
