.open-roles__header {
    display: flex;
    padding-bottom: 40px;
    border-bottom: 1px solid var(--color-primary);
}

.open-roles__entry {
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
    border-bottom: 1px solid var(--color-primary);
}

.open-roles__entry-title {
    text-decoration: none;

    .h4 {
        color: var(--color-primary);
        font-weight: var(--font-weight-bold);
    }
}

.open-roles__list {
    margin: 0;
    padding: 0;
}
